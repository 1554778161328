import React, { useContext, useMemo } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";

import useAppSetting from "contexts/AppSettingsContext";
import { AuthenticationContext } from "contexts/AuthenticationContext/index";
import { AuthenticationScreen } from "screens/AuthenticationScreen";
import NotFound from "screens/NotFoundScreen";
import SplashScreen from "screens/SplashScreen";
import { useFleetViewVisibility } from "shared-hooks/use-fleet-view-visibility";
import { useLDFlags } from "shared-hooks/use-ld-flags";
import { useWayfinderSettingsVisibility } from "shared-hooks/use-wayfinder-settings-visibility";
import {
  ADHERENCE_PATH,
  ALL_VOYAGES_PATH,
  AREA_CONSTRAINT_PATH,
  CREATE_VOYAGE_PATH,
  DASHBOARD_PATH,
  EDIT_ROUTE_PATH,
  EDIT_VOYAGE_PATH,
  FLEET_VIEW_PATH,
  GLOBAL_AREA_CONSTRAINT_SETTINGS_PATH,
  IMPORT_VOYAGE_PATH,
  MLV_REPORTS_PATH,
  NOONS_PATH,
  OLD_ALL_VOYAGES_PATH,
  OLD_CREATE_VOYAGE_PATH,
  OLD_ROUTE_PATH,
  OLD_VOYAGE_PATH,
  OPTIMIZATION_HISTORY_PATH,
  OPTIMIZATION_PATH,
  ORGANIZATION_AREA_CONSTRAINT_SETTINGS_PATH,
  PERFORMANCE_PATH,
  PRINT_PATH,
  REPORTS_PATH,
  ROUTE_EXPLORER_EDIT_ROUTE_PATHS,
  ROUTE_EXPLORER_PATHS,
  ROUTE_EXPLORER_ROUTE_DETAILS_PATHS,
  ROUTE_PATH,
  SEAKEEPING_PATH,
  SETTINGS_PATH,
  useWayfinderUrlUuids,
  VESSEL_DETAILS_BASE_PATH,
  VOYAGE_PATH,
} from "shared-hooks/use-wayfinder-url";

// Bundle splitting time. Split out screens that may not be used
const VoyageScreen = React.lazy(() => import("screens/VoyageScreen"));
const PrintScreen = React.lazy(() => import("screens/PrintScreen"));

const AppSettingsScreen = React.lazy(() => import("screens/AppSettingsScreen"));

export const FULL_VESSEL_DETAILS_PATHS = [
  VESSEL_DETAILS_BASE_PATH,
  ADHERENCE_PATH,
  PERFORMANCE_PATH,
  NOONS_PATH,
  REPORTS_PATH,
  MLV_REPORTS_PATH,
];

export const SETTINGS_PATHS = [
  SETTINGS_PATH,
  GLOBAL_AREA_CONSTRAINT_SETTINGS_PATH,
  ORGANIZATION_AREA_CONSTRAINT_SETTINGS_PATH,
];

// universally allowed paths that require a voyage screen
export const BASE_VOYAGE_SIDEBAR_PATHS = [
  `${ROUTE_PATH}?`,
  `${EDIT_ROUTE_PATH}/`,
  `${EDIT_VOYAGE_PATH}/`,
  `${IMPORT_VOYAGE_PATH}/`,
  `${VOYAGE_PATH}/`,
  `${CREATE_VOYAGE_PATH}/`,
  `${ALL_VOYAGES_PATH}/`,
  // DEPRECATED: but we keep them around so we can redirect to them
  `${OLD_ALL_VOYAGES_PATH}/`,
  `${OLD_CREATE_VOYAGE_PATH}/`,
  `${OLD_VOYAGE_PATH}/`,
  `${OLD_VOYAGE_PATH}?`,
  `${OLD_ROUTE_PATH}?`,
];
export const BASE_VOYAGE_SCREEN_PATHS = [
  ...BASE_VOYAGE_SIDEBAR_PATHS,
  `${DASHBOARD_PATH}/`,
  `${SEAKEEPING_PATH}/`,
  `${OPTIMIZATION_PATH}/`,
  `${OPTIMIZATION_HISTORY_PATH}/`,
  `${AREA_CONSTRAINT_PATH}/`,
];
export const ALL_ROUTE_EXPLORER_PATHS = [
  ...ROUTE_EXPLORER_EDIT_ROUTE_PATHS,
  ...ROUTE_EXPLORER_ROUTE_DETAILS_PATHS,
  ...ROUTE_EXPLORER_PATHS,
];

const VoyageScreenRoutes: React.FC<{
  enablePrint: boolean;
  allowFleetView: boolean;
  showVesselOverview: boolean;
  voyageScreen: JSX.Element;
  showSettings: boolean;
  showRouteExplorer: boolean;
}> = ({
  enablePrint,
  voyageScreen,
  allowFleetView,
  showVesselOverview,
  showSettings,
  showRouteExplorer,
}) => {
  // add on paths for features with controlled access
  const enabledPaths = useMemo(() => {
    const paths = [...BASE_VOYAGE_SCREEN_PATHS];
    if (allowFleetView) paths.push(FLEET_VIEW_PATH);
    if (showVesselOverview) paths.push(...FULL_VESSEL_DETAILS_PATHS);
    if (showSettings) paths.push(...SETTINGS_PATHS);
    if (showRouteExplorer) paths.push(...ALL_ROUTE_EXPLORER_PATHS);
    return paths;
  }, [allowFleetView, showVesselOverview, showSettings, showRouteExplorer]);
  return (
    <Switch>
      <Route exact path={enabledPaths}>
        {voyageScreen}
      </Route>
      <Route exact path={`${VOYAGE_PATH}${PRINT_PATH}`}>
        {enablePrint && <PrintScreen />}
      </Route>
      <Redirect to="/not-found" />
    </Switch>
  );
};

export const RouterSwitch = () => {
  const { featureIsAllowed, metadata, metadataIsLoading } = useContext(
    AuthenticationContext
  );
  const { vesselUuid } = useWayfinderUrlUuids();

  const shouldSwapVesselUuid =
    !metadataIsLoading &&
    // only captain's account has vessel uuid
    metadata?.vesselUuid &&
    vesselUuid &&
    metadata.vesselUuid !== vesselUuid;

  const showVesselOverview = featureIsAllowed("vesselDetailsPage");
  const showSettings = useWayfinderSettingsVisibility();
  const allowFleetView = useFleetViewVisibility();
  const { showRouteExplorer } = useLDFlags();

  const { value: enablePrint } = useAppSetting("enablePrint");

  // keep the voyage screen around so we are not needlessly destroying it
  const voyageScreen = useMemo(() => <VoyageScreen />, []);

  const routerProps = useMemo(
    () => ({
      allowFleetView,
      enablePrint,
      voyageScreen,
      showVesselOverview,
      showSettings,
      showRouteExplorer,
    }),
    [
      allowFleetView,
      enablePrint,
      showVesselOverview,
      voyageScreen,
      showSettings,
      showRouteExplorer,
    ]
  );

  // FIXME move all redirects to a central location (here) so there are no race conditions
  return (
    <Switch>
      {shouldSwapVesselUuid && metadata.vesselUuid && (
        <Redirect
          to={
            window.location.pathname.replace(vesselUuid, metadata.vesselUuid) +
            window.location.search
          }
        />
      )}
      <Route path="/" exact>
        <SplashScreen />
      </Route>
      <Route path="/authenticate">
        <AuthenticationScreen />
      </Route>
      <Route path="/settings">
        <AppSettingsScreen />
      </Route>
      <Route path="/not-found" exact>
        <NotFound />
      </Route>
      {/* Routes to voyage related screens */}
      <VoyageScreenRoutes {...routerProps} />
    </Switch>
  );
};
